<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">หมวดหมู่ย่อย พ.ร.บ. ประกันภัย</div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อบริษัท"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>

      <v-btn
        color="success"
        outlined
        small
        class="ml-5"
        :disabled="!selected.length"
        @click="changedStatus(true)"
      >
        เปิดใช้งาน
      </v-btn>
      <v-btn
        color="error"
        outlined
        small
        class="ml-2"
        :disabled="!selected.length"
        @click="changedStatus(false)"
        >ปิดใช้งาน</v-btn
      >
      <v-btn color="primary" outlined small class="ml-5" @click="addProduct()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มหมวดหมู่ย่อย พ.ร.บ.
        และประกันภัย</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="_id"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @click:row="edit"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item.is_available`]="{ item }">
        <menu-ship
          v-model="item.is_available"
          path="sub_category/changed_insurance_sub_category_status"
          :params="{ sub_category_id: item._id }"
          @success="$alertSuccess(), getData()"
        />
      </template>
      <template v-slot:[`item.third_insurance_count`]="{ item }">
        {{ item.third_insurance_count | numeral('0,0') }}
      </template>
      <template v-slot:[`item.insurance_count`]="{ item }">
        {{ item.insurance_count | numeral('0,0') }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    listIsAvailable: [],
    selected: [],
    select_insurance_id: '',
    sub_category_id: '',
    branch_id: [],
    totalTax: null,
    updateInprogress: 0,
    updatePending: 0,
    loading: true,
    pageCount: 0,
    list: [],
    headers: [
      { text: 'ชื่อบริษัท', value: 'name' },
      { text: 'นายหน้า', value: 'broker', filterable: false },
      { text: 'สาขา', value: 'branch_id', filterable: false },
      { text: 'สินค้า พรบ', value: 'third_insurance_count', filterable: false },
      { text: 'สินค้าประกันภัย', value: 'insurance_count', filterable: false },
      { text: 'สถานะ', value: 'is_available', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    filter: {
      item_per_page: 200,
      page: 1,
      start_with: '',
      branch_id: 0
    }
  }),
  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },

  methods: {
    changedStatus(status) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let sub_category_id = [];
            this.selected.forEach(element => {
              sub_category_id.push(element._id);
            });

            let fill = {
              sub_category_id: sub_category_id,
              is_available: status
            };
            let body = {
              token: this.$jwt.sign(fill, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(
                `${this.$baseUrl}/sub_category/changed_insurance_sub_category_status`,
                body
              )
              .then(res => {
                this.selected = [];
                this.$alertSuccess({ title: res.status });
                this.getData(this.id);
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    addProduct() {
      this.$router.push({
        name: 'product-insurance-create'
      });
    },
    edit(item) {
      this.$router.push({
        name: 'product-insurance-update',
        params: { id: item._id }
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { sub_category_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(`${this.$baseUrl}/sub_category/delete_sub_category`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/sub_category/get_insurance_sub_category`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
